.aboutus {
    word-break: break-word;
    position: relative;

    .stickydiv {
        position: sticky;
        top: 70px;
    }

    .banner {
        margin-top: 70px;
        background-color: $primary;

        .bannerContainer {
            display: flex;
            justify-content: start;
            align-items: baseline;
            padding: 80px;
            gap: 20px
        }

        .quote {
            margin-left: 50px;
            width: 60%;
        }
    }

    .header1 {
        color: $primary;
        font-weight: $font-weight-semibold;
        font-size: 26px;
    }

    img {
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        z-index: 1;
        object-position: center;
        height: 400px;
        border-radius: 32px;
    }

    .img-2 {
        img {
            height: 500px;
        }
    }

    .header-left {
        color: $primary;
        font-weight: $font-weight-normal;
        font-size: 36px;
        border-left: 2px solid $primary;
        padding-left: 30px;
        width: fit-content;
    }

    .header-right {
        color: $primary;
        font-weight: $font-weight-normal;
        font-size: 36px;
        border-right: 2px solid $primary;
        padding-right: 50px;
        width: fit-content;
    }

    .text1 {
        color: $black;
        font-weight: $font-weight-normal;
        font-size: 20px;
    }

    .text2 {
        color: $black;
        font-weight: $font-weight-light;
        font-size: 18px;
    }

    .text3 {
        color: $primary;
        font-weight: $font-weight-semibold;
        font-size: 18px;
    }

    .text4 {
        color: $black;
        font-weight: $font-weight-semibold;
        font-size: 16px;
    }


    .content {
        padding: 10px 160px;

        .quote {
            font-size: 45px;
        }
    }

    .pageTitle {
        font-size: 36px;
        border-left: 2px solid $white;
        padding-left: 45px;
        color: $white;
        text-wrap: nowrap;
    }

    .quote {
        font-size: 60px;
        color: $secondary;
        font-weight: $font-weight-light;
    }

    .nav {
        font-size: 16px;
        color: $black;
        font-weight: $font-weight-semibold;
        padding: 10px 5px;

        .active {
            color: $black !important;
            text-decoration: none;
        }

    }

    @media (max-width: 768px) {
        .content {
            padding: 5px 40px;
        }

        img {
            height: 300px;
        }

        .img-2 {
            img {
                height: 400px;
            }
        }

        .banner {
            .bannerContainer {
                flex-direction: column;
                padding: 40px;
                gap: 10px
            }

            .pageTitle {
                font-size: 30px;
                text-wrap: wrap;
            }

            .quote {
                font-size: 40px;
                margin-left: 0px;
                width: fit-content;
            }
        }

    }
}