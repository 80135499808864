.products {
    word-break: break-word;


    .banner {
        margin-top: 70px;
        background-color: $primary;

        .bannerContainer {
            display: flex;
            justify-content: start;
            align-items: baseline;
            padding: 80px;
            gap: 20px
        }

        .quote {
            margin-left: 50px;
            width: 60%;
            min-height: 180px;
        }
    }

    img {
        display: block;
        -o-object-fit: cover;
        object-fit: cover;
        z-index: 1;
        width: 100%;
        object-position: center;
        border-radius: 32px;
        border: 1px solid #000;
    }

    .img {
        height: 400px;
    }


    .client-wrapper {
        gap: 20px;
        padding: 20px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%239747FFFF' stroke-width='4' stroke-dasharray='14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 12px;
    }

    .client {
        width: 240px;
        height: 150px;
        padding: 15px;
        border-radius: 32px;
        border: 1px solid #000;

        img {
            border: none;
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
            -o-object-fit: contain;
            z-index: 1;
            width: 100%;
            object-position: center;
            border-radius: 0;
        }
    }

    .header-left {
        color: $primary;
        font-weight: $font-weight-normal;
        font-size: 36px;
        border-left: 2px solid $primary;
        padding-left: 30px;
        width: fit-content;
    }

    .header-right {
        color: $primary;
        font-weight: $font-weight-normal;
        font-size: 36px;
        border-right: 2px solid $primary;
        padding-right: 50px;
        width: fit-content;
    }

    .text1 {
        color: $black;
        font-weight: $font-weight-normal;
        font-size: 20px;

    }

    .title {
        color: $black;
        font-weight: $font-weight-semibold;
        font-size: 18px;

    }

    .description {
        color: $black;
        font-weight: $font-weight-normal;
        font-size: 16px;

    }

    .image-container {
        gap: 50px 0px;

    }

    .content {
        padding: 10px 160px;
    }

    .pageTitle {
        font-size: 36px;
        border-left: 2px solid $white;
        padding-left: 45px;
        color: $white;
        text-wrap: nowrap;
    }

    .quote {
        font-size: 60px;
        color: $secondary;
        font-weight: $font-weight-light;
    }

    @media (max-width: 768px) {
        .content {
            padding: 5px 40px;
        }

        .img {
            height: 300px;
        }


        .banner {
            .bannerContainer {
                flex-direction: column;
                padding: 40px;
                gap: 10px
            }

            .pageTitle {
                font-size: 30px;
                text-wrap: wrap;
            }

            .quote {
                font-size: 40px;
                margin-left: 0px;
                width: fit-content;
                min-height: 120px;
            }

        }

    }
}