.home {
    word-break: break-word;

    .hero {
        --default-color: #ffffff;
        --default-color-rgb: 255, 255, 255;
        --background-color: #000000;
        --background-color-rgb: 0, 0, 0;

        width: 100%;
        min-height: 100vh;
        position: relative;
        padding: 160px 0 80px 0;
        display: flex;
        align-items: center;
        justify-content: center;


        img {
            position: absolute;
            inset: 0;
            display: block;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            z-index: 1;
            object-position: center;
        }

        .container {
            position: relative;
            z-index: 3;
        }

        h2 {
            color: $white;
            font-weight: $font-weight-semibold;
            font-size: 60px;
            text-align: center;
        }

        @media (max-width: 768px) {
            h2 {
                font-size: 45px;
            }
        }
    }

    .who-we-are {
        padding: 80px 0;
        background-color: $white;

        .text-container {
            padding: 0px 160px;

            h2 {
                font-size: 36px;
                border-left: 2px solid $primary;
                padding-left: 45px;
                font-weight: $font-weight-normal;

                width: fit-content;
            }

            h3 {
                margin-top: 50px;
                font-size: 24px;
                font-weight: $font-weight-semibold;
                max-width: 800px;
                margin-left: auto;
                margin-right: auto;
            }

        }

        .carousel-main {
            padding: 40px;

            img {
                display: block;
                width: 100%;
                -o-object-fit: cover;
                height: 700px;
                object-fit: cover;
                z-index: 1;
                object-position: center;
                border-radius: 32px;
            }

            .controller {
                font-size: 40px;
                display: flex;
                justify-content: flex-end;
                gap: 15px;
                padding: 0px 100px;

                i {
                    cursor: pointer;
                }
            }
        }

        .text-ribbon {
            margin: auto;
            padding: 50px 40px;
            justify-content: space-around;

            .parent {
                font-size: 80px;
                color: #F15923;
                font-weight: 500;
            }

            .child1 {
                font-size: 30px;
                color: #F15923;
                line-height: 36px;
                font-weight: $font-weight-semibold;
                text-align: center;
            }

            .child2 {
                font-size: 25px;
            }

            .text {
                margin-top: 25px;
                font-size: 24px;
                text-align: center;
                margin-bottom: 20px;
            }
        }


        @media (max-width: 768px) {
            .text-container {
                padding: 0px 40px;
            }

            .carousel {
                img {
                    height: 400px;
                    gap: 10px;
                }

                .controller {
                    padding: 0px 50px;
                }
            }

            .text-ribbon {
                .text {
                    text-align: center;
                }

                .rowWrapper {
                    justify-content: center;
                }

            }
        }




    }

    .what-we-do {

        width: 100%;
        padding: 0px 20px;

        .image-container {
            margin-top: 50px;
            padding: 0px 160px;

            img {
                display: block;
                -o-object-fit: cover;
                object-fit: cover;
                z-index: 1;
                width: 100%;
                object-position: center;
                border-radius: 32px;
                border: 1px solid #000;
            }

            .text1 {
                margin-top: 25px;
                text-align: start;
                font-size: 30px;
                font-weight: $font-weight-semibold;
            }

            .text2 {
                text-align: justify;
                margin-top: 15px;
                font-size: 24px;
            }
        }

        .text-container {
            padding: 0px 160px;

            h2 {
                font-size: 36px;
                border-left: 2px solid $primary;
                padding-left: 45px;
                font-weight: $font-weight-normal;
                width: fit-content;

            }
        }

        @media (max-width: 768px) {
            .text-container {
                padding: 0px 40px;
            }

            .image-container {
                padding: 0px 40px;

                img {
                    width: 80%;
                    margin: auto;
                }
            }
        }
    }

    .problem {

        margin-top: 50px;
        margin-bottom: 100px;

        .image-container {
            padding: 40px;

            img {
                display: block;
                -o-object-fit: cover;
                object-fit: cover;
                z-index: 1;
                width: 100%;
                height: 600px;
                object-position: center;
                border-radius: 32px;
                border: 1px solid #000;
            }
        }

        .text-container {
            padding: 0px 160px;

            .quote {
                margin-top: 100px;
                font-size: 60px;
                font-weight: $font-weight-light;
                color: $secondary;
                text-align: center;
                margin-bottom: 50px;

            }

            h1 {
                font-size: 36px;
                border-left: 2px solid $primary;
                padding-left: 45px;
                margin-top: 100px;
                font-weight: $font-weight-normal;
                width: fit-content;

            }

            h2 {
                font-weight: $font-weight-normal;
                margin-top: 50px;
                font-size: 36px;
                border-right: 2px solid $primary;
                padding-right: 45px;
                width: fit-content;
                margin-top: 100px;
            }

            h3 {
                margin-top: 50px;
                font-size: 30px;
                font-weight: $font-weight-semibold;
                color: #000;
            }

            p {
                margin-top: 30px;
                font-size: 24px;
                color: #000;
                width: 80%;
            }



        }

        @media (max-width: 768px) {
            .text-container {
                padding: 0px 40px;

                img {
                    height: 300px;
                }

                .quote {
                    font-size: 40px;
                }

                p {
                    width: 100%
                }
            }

            .image-container {
                padding: 20px;

            }

        }
    }

    .contact {
        margin-top: 50px;
        margin-bottom: 100px;

        .text-container {
            padding: 0px 160px;


            h1 {
                font-size: 36px;
                border-left: 2px solid $primary;
                padding-left: 45px;
                margin-top: 100px;
                font-weight: $font-weight-normal;
                width: fit-content;
            }

            h3 {
                margin-top: 50px;
                font-size: 30px;
                font-weight: $font-weight-semibold;
            }

            p {
                margin-top: 30px;
                font-size: 24px;
                width: 80%;
            }

        }

        @media (max-width: 768px) {
            .text-container {
                padding: 0px 40px;

                img {
                    height: 300px;
                }

                .quote {
                    font-size: 40px;
                }

                p {
                    width: 100%
                }

            }


        }
    }
}