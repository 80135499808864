.contactus {
    word-break: break-word;

    .banner {
        margin-top: 70px;
        background-color: $primary;

        .bannerContainer {
            display: flex;
            justify-content: start;
            align-items: baseline;
            padding: 80px;
            gap: 20px
        }

        .quote {
            margin-left: 50px;
            width: 60%;
            min-height: 180px;
        }
    }

    h1 {
        font-size: 36px;
        border-left: 2px solid $primary;
        padding-left: 45px;
        margin-top: 100px;
        font-weight: $font-weight-normal;
        width: fit-content;
    }

    h3 {
        margin-top: 50px;
        font-size: 30px;
        font-weight: $font-weight-semibold;
    }

    p {
        margin-top: 30px;
        font-size: 24px;
        width: 80%;
    }

    .logo {
        max-width: 400px;
    }

    .icons {
        font-size: 25px;
        color: $primary;

        i {
            cursor: pointer;
        }
    }

    iframe {
        width: 100%;
        height: 400px;
        border: none;


    }

    .header {
        color: $primary;
        font-weight: $font-weight-semibold;
        font-size: 18px;
        width: fit-content;
    }

    .text {
        color: $black;
        font-weight: $font-weight-normal;
        font-size: 18px;
    }

    .content {
        padding: 10px 160px;
    }

    .pageTitle {
        font-size: 36px;
        border-left: 2px solid $white;
        padding-left: 45px;
        color: $white;
        text-wrap: nowrap;
        width: fit-content
    }

    .quote {
        font-size: 60px;
        color: $secondary;
        font-weight: $font-weight-light;
    }

    @media (max-width: 768px) {
        .content {
            padding: 5px 40px;
        }

        iframe {
            width: 100%;
            height: 250px;
            border: none;
        }

        .banner {
            .bannerContainer {
                flex-direction: column;
                padding: 40px;
                gap: 10px
            }

            .pageTitle {
                font-size: 30px;
                text-wrap: wrap;
            }

            .quote {
                font-size: 40px;
                margin-left: 0px;
                width: fit-content;
                min-height: 120px;
            }

        }

    }
}