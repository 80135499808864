@import '~bootstrap/scss/bootstrap';

.nav-link {
  font-weight: $font-weight-semibold;
  font-size: 16px;
  letter-spacing: 21;
  color: #FFFFFF;
  margin: 0px 10px;
}

.active {
  color: $secondary !important
}

.navbar {
  padding: 10px 50px;
}

@media (max-width: 768px) {
  .navbar {
    padding: 10px;
  }
}

.navbar-brand {
  font-family: "basic-sans";
  height: 50px;
}

.section {
  padding: 56px 0 0 0;
}

.footer {
  padding: 50px;

  .logo1 {
    height: 100px;
  }

  .logo2 {
    height: 100px;

  }
}

.pagenotfound {
  padding: 160px 0 80px 0
}

.mt-15 {
  margin-top: 15px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-100 {
  margin-top: 100px;
}


@media (max-width: 768px) {

  .footer {
    .logo1 {
      display: none;
    }
  }

  .mt-15 {
    margin-top: 7.5px;
  }

  .mt-25 {
    margin-top: 12.5px;
  }

  .mt-30 {
    margin-top: 15px;
  }

  .mt-40 {
    margin-top: 20px;
  }

  .mt-50 {
    margin-top: 25px;
  }

  .mt-70 {
    margin-top: 35px;
  }

  .mt-100 {
    margin-top: 50px;
  }



}